import React, {useState} from 'react';
import styles from './CustomerDetails.module.css';
import { Button } from 'react-bootstrap';
import axios from 'axios';
import { URL_LIST, DOMAIN_LIST } from '../../../constants/apiList';
import { STATIC_URL_KEYS, getStaticUrl, getAlternateStaticUrl } from '../../../constants/brandConfig'; 
import { getParsedOrderId } from '../../../helpers';
import { toast } from 'react-toastify';
import Loader from 'react-loader-spinner';
import AppointmentDetails from '../AppointmentDetails/AppointmentDetails';
import SoDetials from '../soDetails/soDetails';
import { IoIosArrowDropdownCircle, IoIosArrowDropupCircle} from 'react-icons/io';
import SubscriptionDetails from '../SubscriptionDetails/SubscriptionDetails';
import OrderDetails from '../OrderDetails/OrderDetails';
import RefundDetails from '../OrderDetails/RefundDetails';
import { getBrand } from '../../../helpers';


function CustomerDetails(props) {
  const query = new URLSearchParams(props?.search);
  const searchByQuery = query.get('searchBy') || "appointment_id"
  const searchByValue = query.get('searchValue') || ""
  const [searchBy, setSearchBy] = useState(searchByQuery);
  const [searchValue, setSearchValue] = useState(searchByValue);
  const [loader, setLoader] = useState(false);
  const [customerData, setCustomerData] = useState(null);
  const [isAlternate, setIsAlternate] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [moreAddress, setMoreAddress] = useState(false);
  const searchParameters = [
    { title: "APPOINTMENT ID", value: "appointment_id"},
    { title: "ORDER ID", value: "order_id"},
    { title: "EMAIL", value: "email"},
    { title: "PHONE", value: "phone"},
    { title: "USER ID", value: "user_id"},
  ];
  const prepareAddress = (address) => {
    let addressString = '';
    if(address.address_line1) addressString += `${address.address_line1}, `;
    if(address.address_line2) addressString += `${address.address_line2}, `;
    if(address.pincode) addressString += `${address.pincode}, `;
    if(address.city) addressString += `${address.city}, `;
    if(address.state) addressString += `${address.state}, `;
    if(address.pinCode) addressString += `${address.pinCode}`;
    return addressString;
  }
  const searchAppointments = async () => {
    setAppointments([]);
    const requestData = {
      searchBy,
      searchValue,
    }
    axios.post(URL_LIST.SEARCH_APPOINTMENTS, requestData)
    .then(res=>{
      if(res.data.success) {
        setAppointments(res.data.result);
      }
      else {
        toast.error(res.data.message);
      }
    })
    .catch(err=>{
      toast.error("Unable to search appointments");
      console.log("ERROR IN /searchAppointments", err);
    })
  }
  const searchCustomer = async () => {
    setLoader(true);
    setCustomerData(null);
    let finalSearchValue = searchValue;
    if(searchBy === "order_id") finalSearchValue = getParsedOrderId(searchValue);
    const requestData = {
      search_by: searchBy,
      search_value: finalSearchValue,
    }
    let url = URL_LIST.SEARCH_CUSTOMER;
    if(searchBy==="appointment_id") {
      requestData.appointmentId = searchValue;
      url = URL_LIST.SEARCH_CUSTOMER_BY_APPOINTMENT_ID;
    }
    axios.post(url, requestData)
    .then(res=>{
      if(res.data.status) {
        setCustomerData(res.data.result);
        setIsAlternate(res.data.isAlternate);
      }
      else {
        toast.info("No Data Found!");
      }
      setLoader(false);
    })
    .catch(err=>{
      setLoader(false);
      toast.error("Unable to search customer details");
      console.log("ERROR IN /searchCustomer", err);
    })
  }
  const onSearchClick = () => {
    setMoreAddress(false);
    searchAppointments();
    searchCustomer();
  }
  const walletLink = isAlternate ? (customerData?getStaticUrl(STATIC_URL_KEYS.WALLET_LINK, {email: customerData.email}): "") : (customerData?getAlternateStaticUrl(STATIC_URL_KEYS.WALLET_LINK, {email: customerData.email}): "");
  return (
    <div className={styles["customer-details"]}>
      <div className="page-heading">Customer details</div>
      <div className={styles["main-content"]}>
        <div>Search By</div>

        <div className="radio-group">
          {
            searchParameters.map(sp=>{
              return (
              <div className="radio-input" onClick={evt=>setSearchBy(sp.value)}>
                <input type="radio" className="regular-radio-button" name="searchBy" checked={searchBy===sp.value} />
                <div className={`input-label ${searchBy===sp.value?'active':''}`}>{sp.title}</div>
              </div>
              )
            })
          }
        </div>
        <div className={styles["search-form"]}>
          <input
          value={searchValue}
          onChange={evt=>setSearchValue(evt.target.value)}
          type={`${searchBy==="order_id" || searchBy==="appointment_id"?"number":"text"}`}
          placeholder={searchBy} className="form-control"
          style={{width: "370px"}}
          />
          <Button className="regular-button" style={{marginLeft: "15px"}} disabled={!searchValue} onClick={onSearchClick}>
            Search
          </Button>
        </div>
        {
          customerData?.number?.includes("_mw_") && 
          <div className={styles["deleted-alert"]}>
            This customer has deleted their account. Please do not share any profile/order details with the customer.
          </div>
        }
        {
          loader?
          <Loader
          type="ThreeDots"
          width={40}
          height={40}
          color="#144166"
          />
          :
            customerData?
            <div>
              <div className={styles["main-content"]}>
                <div className={styles["info-card"]}>
                  <div className="page-heading secondary">Basic Information
                  {customerData?.daysLeft != 0 && (
                    <span style={{ background: '#f44336', color: '#fff', padding: '5px', borderRadius: '8px', marginLeft: '10px' }}>
                      Days Left to Inactivate: {customerData?.daysLeft}
                    </span>
                  )}
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Name</div>
                    <div className={styles["value"]}>{customerData.name}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Contact</div>
                    <div className={styles["value"]}>{customerData.number}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Email ID</div>
                    <div className={styles["value"]}>{customerData.email}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Customer Id</div>
                    <div className={styles["value"]}>{customerData.customerId}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Juspay Id</div>
                    <div className={styles["value"]}>{customerData.juspay_customer_id}</div>
                  </div>
                </div>
                <div className={styles["info-card"]}>
                  <div className="page-heading secondary">Other Details</div>
                  <div className={styles["row"]} style={{alignItems: "flex-start"}}>
                    <div className={styles["field"]}>Address</div>
                    <div className={styles["value"]}>
                      {customerData.address.length?
                      <div style={{display: "flex"}}>
                        {
                          moreAddress?
                          <>
                          <div className={styles["more-address"]}>
                            {
                              customerData.address.map(address=>{
                                return (<div>{prepareAddress(address)}</div>);
                              })
                            }
                          </div>
                          <button className={styles["toggle-button"]} onClick={evt=>setMoreAddress(false)}>
                            <IoIosArrowDropupCircle style={{width: "25px", height: "25px"}} />
                          </button>
                          </>
                          :
                          <>
                          <div className={styles["less-address"]}>
                            {prepareAddress(customerData.address[0])}
                          </div>
                          <button className={styles["toggle-button"]} onClick={evt=>setMoreAddress(true)} disabled={customerData.address.length===1}>
                            <IoIosArrowDropdownCircle style={{width: "25px", height: "25px"}} />
                          </button>
                          </>

                        }
                      </div>
                      :"N/A"
                      }
                    </div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Type</div>
                    <div className={styles["value"]}>{customerData.type}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Orders</div>
                    <div className={styles["value"]}>{customerData.order_count?customerData.order_count:0}</div>
                  </div>
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Appointments</div>
                    <div className={styles["value"]}>{appointments?.length}</div>
                  </div> 
                  <div className={styles["row"]}>
                    <div className={styles["field"]}>Wallet Details</div>
                    <div className={styles["value"]}><a href={walletLink} target="_blank" style={{color: "#144166"}}>Show Details</a></div>
                  </div>
                </div>
              </div>
              <div style={{ display: 'flex', justifyContent: '', gap: '20px' }}>
                <div style={{ background: '#ffffb3', padding: '5px', borderRadius: '8px',alignSelf : 'baseline'}}>
                  <div className="page-heading secondary">Shipped Orders - {customerData.order_status_counts?.shipped || 0}</div>
                </div>
                <div style={{ background: ' #d9ffb3', color: '#fff', padding: '5px', borderRadius: '8px' , alignSelf : 'baseline'}}>
                  <div className="page-heading secondary">Completed Orders - {customerData.order_status_counts?.complete || 0} </div>
                </div>
                <div style={{ background: '#ffd1b3', color: '#fff', padding: '5px', borderRadius: '8px', alignSelf : 'baseline' }}>
                  <div className="page-heading secondary">Canceled Orders - {customerData.order_status_counts?.canceled || 0}</div>
                </div>
                <div style={{ background: '#ff8181', color: '#fff', padding: '5px', borderRadius: '8px', alignSelf : 'baseline' }}>
                  <div className="page-heading secondary">Re-Delivery Orders - {customerData.order_status_counts?.reDelivery || 0}</div>
                </div>
              </div>
              <OrderDetails orders={customerData.order_placed} phone={customerData.number} refreshCustomerDetails={onSearchClick} />
              <RefundDetails orders={customerData.refundDetails} refreshCustomerDetails={onSearchClick} />
              <SubscriptionDetails email={customerData.email}/>
              <AppointmentDetails appointments={appointments} />
              <SoDetials customerId={customerData.customerId}/>
            </div>
            :
            null
        }
      </div>
    </div>    
  )
}

export default CustomerDetails;