import React, { useState, useEffect } from 'react';
import styles from './Dashboard.module.css';
import { store } from '../../../store';
import { useHistory } from "react-router";
import CreateAccount from '../CreateAccount/CreateAccount';
import ListUsers from '../ListUsers/ListUsers';
import { ToastContainer } from 'react-toastify';
import CustomerDetails from '../CustomerDetails/CustomerDetails';
import PatientHistory from '../PatientHistory';
import UserInfo from '../UserInfo'
import { BRAND_CONFIG_KEYS, getBrandConfig } from '../../../constants/brandConfig';
import CustomerSOPasses from '../soPasses/CustomerSOPasses';

function Dashboard(props) {
  const tabs = [
    { title: "Users", value: "users", component : <ListUsers />, access: ["admin"]},
    { title: "Customer Details", value: "customerDetails", component : <CustomerDetails search={props?.location?.search || "?"}/>, access: ["admin", "agent"]},
    { title: "Customer Passes", value: "customerSOPasses", component : <CustomerSOPasses />, access: ["admin", "agent"]},
    // { title: "Patient History", value: "patientHistory", component : <PatientHistory />, access: ["admin", "agent"]},
    { title: "User Info", value: "userInfo", component : <UserInfo />, access: ["admin", "category"]},
    { title: "Create Account", value: "createAccount", component : <CreateAccount />, access: ["admin"]},
  ];
  const allowedTabs = tabs.filter(tab=>tab.access.includes(props.role));
  const query = new URLSearchParams(props?.location?.search);
  const screen = query.get('screen');
  const defaultTab = screen ? allowedTabs.find((x=>{ return x?.value === screen})) || allowedTabs[0] || {} : allowedTabs[0] || {}
  const history = useHistory();
  const [activeTab, setActiveTab] = useState(defaultTab);
  const renderTabs = () => {
    const onTabClick = (tab) => {
      setActiveTab(tab);
    }
    return <div>
      {
        allowedTabs.map((tab,index)=>{
          return <div className={`${styles["tab"]} ${tab.value===activeTab.value?styles['active']:''}`} onClick={evt=>onTabClick(tab)}>
            {tab.title}
          </div>
        })
      }
    </div>
  }
  return (
    <div className={styles["dashboard"]}>
      <div className={styles["side-bar"]}>
        <div className={styles["tab-container"]}>
          <div className={styles["dashboard-title"]}>
            CS DASHBOARD FOR
            <img src={getBrandConfig(BRAND_CONFIG_KEYS.LOGO)} />
          </div>
          {renderTabs()}
        </div>
        <div className={styles["logout-button-container"]}>
          <button className={`regular-button ${styles["logout-button"]}`} onClick={evt=>{store.dispatch({type: "CLEAR", payload: null}); history.push("/")}}>
            LOGOUT
          </button>
        </div>
      </div>
      <div className={styles["main-content"]}>
        {activeTab.component}
      </div>
      <ToastContainer
      position="top-right"
      autoClose="5000"
      hideProgressBar="true"
      closeOnClick="true"
      pauseOnHover= "true"
      draggable= "true"
      progress= "0"
      />

    </div>
  );
}

export default Dashboard;